import React, {useEffect} from 'react';
import styles from './Terms.module.scss';

const Terms = () => {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return (
        <div className={styles.terms}>
                <h1>Terms of Service</h1>

                <p>
                        Welcome to Traffic devS's Terms of Service (the "Terms"). These Terms govern your (hereafter " you", "your" or "user ") use of this website and the products and services available at this website, http://www.Traffic devS.com/ (the "Webpage"). Please read these Terms carefully before using the services. By accessing this site or using any part of the site or any content or services, you agree to become bound by these Terms. if you do not agree to all the terms and conditions, then you may not access the site or use the content or any services in the site.
                </p>
                <h2>
                        1.General Use
                </h2>
                <p>
                        Subject to these Terms, Traffic devS hereby grants you a limited, non-exclusive, and non-transferable license to use the Webpage. You can simply visit and use the Webpage and you do not need to register with Traffic devS to do so. We reserve the right to modify these Terms at any time. By continuing to access or use the Site or Service, you agree to such modifications.
                </p>
                <h2>
                        2. Accepting the Terms
                </h2>
                <p>
                        In order to use the Services, the User must first agree to the Terms. The User may not use the Services if he/she does not accept the TOS. If the User is already using the Services, but the User has not accepted the TOS, then the User must stop using Traffic devS 's Services immediately.
                </p>
                <h2>
                        3. Paying fees
                </h2>
                <p>
                        <span className={styles.terms__bolder}>3.1.</span> User is responsible for choosing the appropriate Traffic devS services that appropriately meets the User's needs accounting for corresponding Traffic devS's fees and charges.
                </p>

                <p>
                        <span className={styles.terms__bolder}>3.2.</span> The User is paying Traffic devS on a cost-per-action (CPA) basis, for Valid Transactions. For purposes of this Terms, a "Valid Transaction" must meet all requirements, as determined in Traffic devS's sole discretion. A transaction may be deemed unacceptable to if they are generated through automated or fraudulent means, are not generated by a natural person who has a legitimate and bona fide interest in the advertised product or service, originate from a misleading or automated source.
                </p>

                <p>
                        <span className={styles.terms__bolder}>3.3.</span> The fees for Traffic devS Services and any and all other charges associated with Traffic devS services incurred by the User (e.g. taxes, transaction fees) will be appropriately charged to the User. The length of User's billing cycle shall be 1 calendar month, and all payments under this agreement are due within 30 days from the end calendar month.
                </p>
<p>
        <span className={styles.terms__bolder}>3.4.</span> To use Traffic devS Service the User must provide valid payment method. User always bears personal responsibility for any and all charges associated with the service purchased. Failure to settle outstanding obligations for purchased services may result in account suspension. The User is hereby on notice that Certain Payment Methods may lead to additional financial surcharges, thus the User has to check with his or her Payment Method service provider to verify transactional costs of payment.
</p>

                <p>
                        <span className={styles.terms__bolder}>3.5.</span> Updating your Payment Methods. The User is responsible to continuously keep his or her Payment Method valid and accurate. The User may his or her Payment Methods by going to the "Account" page. Traffic devS may update User's Payment Methods based on information provided by the payment service providers. Following any Payment Method update, the User authorizes Traffic devS to continue to charge the appropriate Payment Method(s).
                </p>

                <p>
                        <span className={styles.terms__bolder}>3.6.</span> The User is responsible for his or her choice of billing method to satisfy any and all fees and charges associated with the purchased service. Thus, the User bears responsibility for choosing a billing method that meets his or hers needs. Therefore, the User is on notice that he or she is responsible for all Traffic devS Automatic Bill Charges and Payments from the time the User signs up for the Traffic devS Automatic Billing payment method and until the User cancels this payment method in his or her "Account" page. The User in his or her discretion may enroll in Traffic devS Automatic Bill Payments by registering his or hers payment method and selecting Automatic Billing option. The User can enroll in Automatic Bill Payments or cancel Automatic Bill payment at any time. The Traffic devS Automatic Bill Payments enrolment and cancelation is located on the "Account" page.
                </p>
                <h2>
                        4. IAB/AAAA Standard Terms and Conditions.
                </h2>
                <p>
                        This Terms are be governed by the IAB/AAAA Standard Terms and Conditions for Internet Advertising for Media Buys One Year or Less, Version 3.0, available at http://www.iab.com/wp-content/uploads/2015/06/IAB_... (the "Terms and Conditions"), which are hereby incorporated herein by reference, as modified by this Terms. Each party hereby acknowledges that it has been provided an opportunity to review the Terms and Conditions and agrees to be bound by them.
                </p>
                <h2>
                        5. Non-commercial Use
                </h2>
                <p>
                        <span className={styles.terms__bolder}>5.1.</span> The User agrees not to reproduce, copy, sell or use for commercial purposes any parts of the Services, not to access (or attempt to access) any of the Services by any means other than through the interface that is provided by Traffic devS, unless the User is granted special rights by Traffic devS specifically in order to do so. Traffic devS does not bear any responsibility for any legal agreements between the User and any other third parties.
                </p>
<p>
        <span className={styles.terms__bolder}>5.2.</span> The User agrees to refrain from trying to crack any of Traffic devS's software or data or to decompile the Traffic devS software source codes used in the Services with the intention to install it on his/her PC, phone, pocket PC or other electronic device.
</p>
                <h2>
                        6. Exclusion of Warranties
                </h2>
                <p>
                        <span className={styles.terms__bolder}>6.1.</span> TRAFFIC DEVS strives to provide the most accurate and pristine service to meet the User's needs and demands. However, Traffic devS does not guarantee that the services provided are uninterrupted and free from error.
                </p>

                <p>
                        <span className={styles.terms__bolder}>6.2.</span> Traffic devS does not bear responsibility for the Content created and displayed by other Users through the Services.
                </p>

                <p>
                        <span className={styles.terms__bolder}>6.3.</span> The User is solely responsible for any breach of any sort of third party rights.
                </p>

                <p>
                        <span className={styles.terms__bolder}>6.4.</span> If the use of the Services by the User results in complaints or claims from a third party addressed to Traffic devS concerning the breach of their rights (including copyright infringement or a person(s) depicted in the photos in the Content who does not want the images to be used in the Services) the User is held fully responsible and must take actions on her/his own to satisfy such claims and complaints.
                </p>

                <p>
                        <span className={styles.terms__bolder}>6.5.</span> Traffic devS may (but has no obligation to) review, modify or remove Content published by the User.
                </p>

                <p>
                        <span className={styles.terms__bolder}>6.6.</span> Traffic devS is not liable to the User for any loss of information which the User publishes on or with the Services of Traffic devS.
                </p>

                <p>
                        <span className={styles.terms__bolder}>6.7.</span> The User understands and agrees that:
                </p>

                <p>
                        <span className={styles.terms__bolder}>a.</span> The Services are provided "as is". Traffic devS does not guarantee that the Services will accommodate or fulfill the User's purpose(s) for using them;
                </p>

                <p>
                        <span className={styles.terms__bolder}>b.</span> Traffic devS does not guarantee that the Services will meet the User's requirements or expectations; that the Services will be uninterrupted, timely, secure or free from error; that any information obtained by the User as a result of his/her use of the Services will be accurate or reliable; that the quality of any product, information, etc. obtained by the User as a result of his/her use of the Services will meet the User's expectations; that defects in the operation or functionality of any software provided to the User as part of the Services will be corrected;
                </p>

                <p>
                        <span className={styles.terms__bolder}>c.</span> The User understands that he/she uses the information obtained through use the Services at his/her own risk;
                </p>

                <p>
                        <span className={styles.terms__bolder}>d.</span> Traffic devS is not liable to the User for any direct or indirect losses which may be incurred by the User due to the availability or unavailability of the Services or the behavior of a third party concerning the Services;
                </p>

                <p>
                        <span className={styles.terms__bolder}>e.</span> The Services may include hyper-links to other websites or content or resources. The User acknowledges and agrees that Traffic devS is not responsible for the availability of any such external sites or resources, for their content, and/or for any loss or damage which may be incurred by the User as a result of his/her use of such external sites or resources.
                </p>

                <h2>
                        7. Other General Legal Terms
                </h2>

                <p>
                        <span className={styles.terms__bolder}>7.1 </span>Unless the User has agreed otherwise in writing with Traffic devS, all complaints, claims, requests, questions and other inquiries must be addressed to contact@Traffic devS.com.
                </p>

                <p>
                        <span className={styles.terms__bolder}>7.2 </span>The Terms of Service (TOS) is a binding agreement between the User and Traffic devS, which regulates the User's use of Traffic devS 's Services.
                </p>
                <p>
                        <span className={styles.terms__bolder}>7.3.</span> Any dispute arising out of or in connection with this Terms, including any question regarding its existence, validity or termination, shall be referred to and finally resolved by arbitration administered by the Singapore International Arbitration Centre ("SIAC") in accordance with laws of Singapore and Arbitration Rules of the Singapore International Arbitration Centre ("SIAC Rules") for the time being in force, which rules are deemed to be with the intent to impersonate that person; incorporated by reference in this clause.
                </p>

                <h2>
                        8. Confidentiality & Privacy Policy
                </h2>

               <p>
                       <span className={styles.terms__bolder}>8.1.</span> Parties agree not to disclose other Party Confidential Information without prior written consent of the other Party. Confidential Information includes without limitation:
               </p>

                <p>
                        <span className={styles.terms__bolder}>a.</span> all Traffic devS software, technology, programming, specifications, materials, guidelines, and documentation;

                </p>
                <p>
                        <span className={styles.terms__bolder}>b.</span> click-through rates or other statistics relating to a party under Agreement; and

                </p>
                <p>
                        <span className={styles.terms__bolder}>c.</span> any other information designated in writing by Traffic devS as "Confidential" or an equivalent designation.

                </p>


                <p>
                        <span className={styles.terms__bolder}>8.2.</span> Traffic devS Confidential Information does not include information that has become publicly known through no breach by You or Traffic devS, or information that has been:
                </p>

                <p>
                        <span className={styles.terms__bolder}>a.</span> independently developed without access to Traffic devS Confidential Information, as evidenced in writing;
                </p>
<p>
        <span className={styles.terms__bolder}>b.</span> rightfully received by a Party from a third party; or
</p>
<p>
        <span className={styles.terms__bolder}>c.</span> required to be disclosed by law or by a governmental authority.
</p>

                <p>
                        <span className={styles.terms__bolder}>8.3.</span> Traffic devS restricts access to the private information of the Users. All User's use of the Traffic devS website are subject to Traffic devS's privacy policy published on this Webpage.
                </p>
                <h2>
                        9. Service Interruption and Force Majeure
                </h2>
                <p>
                        <span className={styles.terms__bolder}>9.1.</span> Traffic devS may temporarily suspend the availability of the Services to carry out scheduled hardware and software tests and/or updates (or any other necessary technical tasks). The User will be sent a notification about the start of such tasks one hour in advance along with information about when the task(s) will be finished.
                </p>
<p>
        <span className={styles.terms__bolder}>9.2.</span> In case of a force majeure, Traffic devS software/hardware failures or breakdowns, failures or breakdowns of the hardware/software of Traffic devS's partners (including failures and breakdowns which occur due to the actions of a third party) the provision of the Services may be interrupted without notification of the User.
</p>
                <h2>
                        10. Changes to the Terms
                </h2>
                <p>
                        <span className={styles.terms__bolder}>10.1.</span> Traffic devS reserves the right to change the Terms of Service (TOS) at any time without providing any advanced notice.
                </p>
<p>
        <span className={styles.terms__bolder}>10.2.</span> The changes to the TOS take effect in 3 days after they are displayed at this Webpage.
</p>
                <p>
                        <span className={styles.terms__bolder}>10.3.</span> The User understands and agrees that if he/she uses the Services after the date on which the Terms of Service have taken effect, Traffic devS will treat his/her use of the Services as acceptance of the updated Terms of Service. If User does not agree with any changes made to the Terms, then he/she must stop using the Services immediately.
                </p>
                <h2>
                        11. Miscellaneous
                </h2>
                <p>
                        No agency, partnership, joint venture, or employment is created as a result of the Terms of Service and you do not have any authority of any kind to bind Traffic devS in any respect whatsoever. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder. Traffic devS shall not be liable for any failure to perform its obligations hereunder where such failure results from any cause beyond Traffic devS 's reasonable control, including, without limitation, mechanical, electronic or communications failure or degradation (including "line-noise" interference). The Terms of Service is not assignable, transferable or sublicensable by you except with Traffic devS's prior written consent. Traffic devS may transfer, assign or delegate the Terms of Service and its rights and obligations without consent. Both parties agree that the Terms of Service is the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of the Terms of Service, and that all modifications must be in a writing signed by both parties, except as otherwise provided herein.
                </p>

                <p>
                        Effective December 1, 2021
                </p>
        </div>
    );
};

export default Terms;