import React from "react";

export const mgidLogo = (width, height) => (
        <svg xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox="0 0 140 45" fill="none">
            <g clipPath="url(#clip0_209_427)">
                <path d="M85.1067 32.425H88.926V18.1293L85.1067 15.9517V32.425Z" fill="#000099"/>
                <path
                    d="M108.026 7.24619V14.9903C106.25 13.383 103.915 12.3932 101.342 12.3932C95.8041 12.3932 91.3164 16.9322 91.3164 22.527C91.3164 28.1218 95.8041 32.6609 101.342 32.6609C103.915 32.6609 106.25 31.6758 108.026 30.0638V32.4252H111.845V5.0686L108.026 7.24619ZM101.7 29.008C98.0766 29.008 95.1357 26.1045 95.1357 22.527C95.1357 18.9496 98.0766 16.0461 101.7 16.0461C105.324 16.0461 108.265 18.9496 108.265 22.527C108.265 26.1092 105.324 29.008 101.7 29.008Z"
                    fill="#000099"/>
                <path
                    d="M50.7334 12.3931C47.8546 12.3931 45.353 13.9673 44.0497 16.2863C42.7463 13.9673 40.2447 12.3931 37.3659 12.3931C35.0791 12.3931 33.0358 13.3876 31.637 14.9572V12.8314H27.8177V32.425H31.637V20.6415C31.637 18.0397 33.7758 15.9281 36.4111 15.9281C39.0464 15.9281 41.1852 18.0397 41.1852 20.6415V32.425H45.0045V20.6415C45.0045 18.0397 47.1433 15.9281 49.7786 15.9281C52.4139 15.9281 54.5527 18.0397 54.5527 20.6415V32.425H58.372V19.9345C58.372 15.7726 54.9537 12.3931 50.7334 12.3931Z"
                    fill="#000099"/>
                <path
                    d="M77.4685 11.5967V14.9903C75.6925 13.3831 73.358 12.3932 70.7847 12.3932C65.2468 12.3932 60.7591 16.9322 60.7591 22.5271C60.7591 28.1219 65.2468 32.6609 70.7847 32.6609C73.358 32.6609 75.6925 31.6758 77.4685 30.0638V31.6098C77.4685 35.126 74.5849 37.9729 71.0234 37.9729C68.6793 37.9729 66.636 36.7332 65.5046 34.8856L62.2009 36.771C63.9912 39.7451 67.2662 41.7436 71.0234 41.7436C76.6903 41.7436 81.2878 37.2046 81.2878 31.6098V13.7743L77.4685 11.5967ZM71.1428 29.008C67.5192 29.008 64.5784 26.1092 64.5784 22.5271C64.5784 18.9496 67.5192 16.0461 71.1428 16.0461C74.7663 16.0461 77.7072 18.9496 77.7072 22.5271C77.7072 26.1092 74.7663 29.008 71.1428 29.008Z"
                    fill="#000099"/>
                <path d="M81.9256 4.48901L77.4714 6.30367L88.9292 12.8317L92.7485 10.6541L81.9256 4.48901Z"
                      fill="#FF0000"/>
                <path d="M85.1099 10.654L88.9292 12.8316L111.845 -0.229248L85.1099 10.654Z" fill="#FF6D70"/>
            </g>
            <defs>
                <clipPath id="clip0_209_427">
                    <rect width="140" height="45" fill="white"/>
                </clipPath>
            </defs>
        </svg>


        )