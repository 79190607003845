import React from "react";

export const financeIcon = (width, height, color='#000000') => (
    <svg width={`${width}`} height={`${height}`} fill={`${color}`} viewBox="0 0 82 64" xmlns="http://www.w3.org/2000/svg">
        <path d="M50.375 47.625C50.375 49.3509 48.9759 50.75 47.25 50.75C45.5241 50.75 44.125 49.3509 44.125 47.625C44.125 45.8991 45.5241 44.5 47.25 44.5C48.9759 44.5 50.375 45.8991 50.375 47.625Z" fill="black"/>
        <path d="M56.625 50.75C58.3509 50.75 59.75 49.3509 59.75 47.625C59.75 45.8991 58.3509 44.5 56.625 44.5C54.8991 44.5 53.5 45.8991 53.5 47.625C53.5 49.3509 54.8991 50.75 56.625 50.75Z" fill="black"/>
        <path d="M69.125 47.625C69.125 49.3509 67.7259 50.75 66 50.75C64.2741 50.75 62.875 49.3509 62.875 47.625C62.875 45.8991 64.2741 44.5 66 44.5C67.7259 44.5 69.125 45.8991 69.125 47.625Z" fill="black"/>
        <path d="M12.875 44.5C11.1491 44.5 9.75 45.8991 9.75 47.625C9.75 49.3509 11.1491 50.75 12.875 50.75H31.625C33.3509 50.75 34.75 49.3509 34.75 47.625C34.75 45.8991 33.3509 44.5 31.625 44.5H12.875Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.75 0.75C4.57233 0.75 0.375 4.94733 0.375 10.125L0.375 53.875C0.375 59.0527 4.57233 63.25 9.75 63.25L72.25 63.25C77.4277 63.25 81.625 59.0527 81.625 53.875V10.125C81.625 4.94733 77.4277 0.75 72.25 0.75L9.75 0.75ZM75.375 13.25V10.125C75.375 8.39911 73.9759 7 72.25 7L9.75 7C8.02411 7 6.625 8.39911 6.625 10.125V13.25L75.375 13.25ZM6.625 25.75L6.625 53.875C6.625 55.6009 8.02411 57 9.75 57L72.25 57C73.9759 57 75.375 55.6009 75.375 53.875V25.75L6.625 25.75Z" fill="black"/>
    </svg>
)