import React from "react";

export const ecommerceIcon = (width, height, color='#000000') => (
    <svg width={`${width}`} height={`${height}`} viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.875 34.75C11.1491 34.75 9.75 36.1491 9.75 37.875V47.25C9.75 48.9759 11.1491 50.375 12.875 50.375C14.6009 50.375 16 48.9759 16 47.25V41H22.25C23.9759 41 25.375 39.6009 25.375 37.875C25.375 36.1491 23.9759 34.75 22.25 34.75H12.875Z" fill="black"/>
        <path d="M72.25 69.125C72.25 70.8509 70.8509 72.25 69.125 72.25H59.75C58.0241 72.25 56.625 70.8509 56.625 69.125C56.625 67.3991 58.0241 66 59.75 66H66V59.75C66 58.0241 67.3991 56.625 69.125 56.625C70.8509 56.625 72.25 58.0241 72.25 59.75V69.125Z" fill="black"/>
        <path d="M44.125 37.875C44.125 36.1491 42.7259 34.75 41 34.75C39.2741 34.75 37.875 36.1491 37.875 37.875V38.4085C34.2338 39.6954 31.625 43.1681 31.625 47.25C31.625 52.4277 35.8223 56.625 41 56.625C42.7259 56.625 44.125 58.0241 44.125 59.75C44.125 61.4759 42.7259 62.875 41 62.875C39.2741 62.875 37.875 61.4759 37.875 59.75C37.875 58.0241 36.4759 56.625 34.75 56.625C33.0241 56.625 31.625 58.0241 31.625 59.75C31.625 63.8319 34.2338 67.3046 37.875 68.5915V69.125C37.875 70.8509 39.2741 72.25 41 72.25C42.7259 72.25 44.125 70.8509 44.125 69.125V68.5915C47.7662 67.3046 50.375 63.8319 50.375 59.75C50.375 54.5723 46.1777 50.375 41 50.375C39.2741 50.375 37.875 48.9759 37.875 47.25C37.875 45.5241 39.2741 44.125 41 44.125C42.7259 44.125 44.125 45.5241 44.125 47.25C44.125 48.9759 45.5241 50.375 47.25 50.375C48.9759 50.375 50.375 48.9759 50.375 47.25C50.375 43.1681 47.7662 39.6954 44.125 38.4085V37.875Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M61.1265 0.694505C61.9787 1.11264 62.5947 1.89545 62.8006 2.82209L65.1955 13.599L68.5121 12.9357C70.1478 12.6085 71.7521 13.6238 72.1567 15.242L74.69 25.375H78.5C80.2259 25.375 81.625 26.7741 81.625 28.5V78.5C81.625 80.2259 80.2259 81.625 78.5 81.625H3.5C1.77411 81.625 0.375 80.2259 0.375 78.5V28.5C0.375 26.7741 1.77411 25.375 3.5 25.375H5.9264L58.4194 0.672441C59.2783 0.268255 60.2743 0.276373 61.1265 0.694505ZM57.5467 7.99059L59.0654 14.825L31.1664 20.4048L57.5467 7.99059ZM68.2477 25.375L66.816 19.6487L38.1844 25.375H68.2477ZM6.625 75.375V31.625H75.375V75.375H6.625Z" fill="black"/>
    </svg>

)