import React from "react";

export const eyeIcon = (width, height, color='#000000') => (
    <svg width={`${width}`} height={`${height}`} viewBox="0 0 82 70" fill={`${color}`} xmlns="http://www.w3.org/2000/svg">
        <path d="M44.125 3.75C44.125 2.02411 42.7259 0.625 41 0.625C39.2741 0.625 37.875 2.02411 37.875 3.75V11.5625C37.875 13.2884 39.2741 14.6875 41 14.6875C42.7259 14.6875 44.125 13.2884 44.125 11.5625V3.75Z" fill="black"/>
        <path d="M23.589 5.71446C22.9481 4.112 21.1294 3.33254 19.527 3.97349C17.9245 4.61444 17.145 6.43308 17.786 8.03554L20.9108 15.848C21.5518 17.4505 23.3704 18.23 24.9729 17.589C26.5753 16.9481 27.3548 15.1294 26.7138 13.527L23.589 5.71446Z" fill="black"/>
        <path d="M5.70971 14.0403C4.48932 12.8199 2.51068 12.8199 1.29029 14.0403C0.0699028 15.2607 0.0699028 17.2393 1.29029 18.4597L7.54029 24.7097C8.76068 25.9301 10.7393 25.9301 11.9597 24.7097C13.1801 23.4893 13.1801 21.5107 11.9597 20.2903L5.70971 14.0403Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28.5 44.375C28.5 37.4714 34.0964 31.875 41 31.875C47.9036 31.875 53.5 37.4714 53.5 44.375C53.5 51.2786 47.9036 56.875 41 56.875C34.0964 56.875 28.5 51.2786 28.5 44.375ZM41 38.125C37.5482 38.125 34.75 40.9232 34.75 44.375C34.75 47.8268 37.5482 50.625 41 50.625C44.4518 50.625 47.25 47.8268 47.25 44.375C47.25 40.9232 44.4518 38.125 41 38.125Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.14658 38.0542C9.49914 31.374 21.7723 19.375 41 19.375C60.2277 19.375 72.5009 31.374 77.8534 38.0542C80.8442 41.7867 80.8442 46.9633 77.8534 50.6958C72.5009 57.376 60.2277 69.375 41 69.375C21.7723 69.375 9.49914 57.376 4.14658 50.6958C1.15584 46.9633 1.15584 41.7867 4.14658 38.0542ZM41 25.625C24.5695 25.625 13.8995 35.8775 9.02401 41.9622C7.86336 43.4108 7.86336 45.3392 9.02401 46.7878C13.8995 52.8725 24.5695 63.125 41 63.125C57.4305 63.125 68.1005 52.8725 72.976 46.7878C74.1366 45.3392 74.1366 43.4108 72.976 41.9622C68.1005 35.8775 57.4305 25.625 41 25.625Z" fill="black"/>
        <path d="M80.7097 14.0403C79.4893 12.8199 77.5107 12.8199 76.2903 14.0403L70.0403 20.2903C68.8199 21.5107 68.8199 23.4893 70.0403 24.7097C71.2607 25.9301 73.2393 25.9301 74.4597 24.7097L80.7097 18.4597C81.9301 17.2393 81.9301 15.2607 80.7097 14.0403Z" fill="black"/>
        <path d="M62.473 3.97349C60.8706 3.33254 59.0519 4.112 58.411 5.71446L55.2862 13.527C54.6452 15.1294 55.4247 16.9481 57.0271 17.589C58.6296 18.23 60.4482 17.4505 61.0892 15.848L64.214 8.03554C64.855 6.43308 64.0755 4.61444 62.473 3.97349Z" fill="black"/>
    </svg>

)
