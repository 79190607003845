import React from "react";

export const admixerLogo = (width, height) => (
        <svg xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox="0 0 151 37" fill="none">
            <path
                d="M90.3693 35.6623C90.3693 36.9685 89.7562 36.9982 88.2622 36.9982C86.7675 36.9982 86.1534 36.9687 86.1534 35.6623V22.6968C86.1534 22.6968 82.8334 30.1473 82.364 30.9467C81.691 32.1332 80.4615 32.163 79.4361 32.163C78.3816 32.163 77.1824 32.1334 76.5087 30.9467C76.04 30.1473 72.7488 22.6968 72.7488 22.6968V35.6623C72.7488 36.9685 72.1073 36.9982 70.6407 36.9982C69.118 36.9982 68.5325 36.9687 68.5325 35.6623V17.5731C68.5325 16.2666 68.5325 16.2368 70.0559 16.2368C71.7268 16.2368 72.9254 16.2664 73.6004 17.453C73.8929 17.9298 79.1142 28.4845 79.4361 29.021C79.7592 28.4845 84.5686 18.6108 85.2714 17.453C85.9467 16.2666 87.1754 16.2368 88.8467 16.2368C90.3414 16.2368 90.3699 16.2664 90.3699 17.5731L90.3693 35.6623Z"
                fill="#D31F35"/>
            <path
                d="M37.9059 32.2219C37.9059 32.2219 36.9707 35.2473 36.8805 35.6624C36.5594 36.9687 35.3602 36.9984 33.8659 36.9984C32.3994 36.9984 32.3413 36.9398 32.6339 35.6624C33.014 34.0604 36.9133 18.7886 37.2364 17.5411C37.5302 16.2662 37.5882 16.2368 39.4349 16.2368C39.6394 16.2368 41.8379 16.2368 42.072 16.2368C43.8904 16.2368 43.9501 16.2662 44.2435 17.5411C44.5646 18.8175 48.5231 34.21 48.8755 35.6624C49.1671 36.9083 49.0791 36.9984 47.6141 36.9984C46.0904 36.9984 44.9216 36.9689 44.5982 35.6624C44.5102 35.277 43.5431 32.2219 43.5431 32.2219H37.9059ZM43.1028 28.7262L40.3486 18.2847H41.1318L38.3771 28.7262H43.1028Z"
                fill="#32323C"/>
            <path
                d="M58.3531 36.998H53.1001C51.605 36.998 51.2841 36.6725 51.2841 35.366V17.8543C51.2841 16.548 51.605 16.2236 53.1001 16.2236H58.3531C64.2468 16.2236 66.2712 18.3622 66.2712 26.6102C66.2715 34.8605 64.2777 36.998 58.3531 36.998ZM54.9194 19.9591V33.2615H58.3533C60.6191 33.2615 61.906 32.8427 61.906 26.61C61.9352 20.3784 60.8033 19.9586 58.3533 19.9586L54.9194 19.9591Z"
                fill="#32323C"/>
            <path
                d="M97.3332 35.6621C97.3332 36.9684 96.72 36.9981 95.227 36.9981C93.7292 36.9981 93.1149 36.9686 93.1149 35.6621V17.5662C93.1149 16.2607 93.7292 16.2297 95.227 16.2297C96.72 16.2297 97.3332 16.2599 97.3332 17.5662V35.6621Z"
                fill="#D31F35"/>
            <path
                d="M145.355 35.6619C144.797 34.7128 142.131 30.8252 141.223 29.2544H138.695L138.692 35.6619C138.692 36.9661 138.078 36.9948 136.584 36.9948C135.089 36.9948 134.474 36.9648 134.475 35.6608L134.479 17.5697C134.479 16.2643 134.509 16.2357 136.001 16.2357H142.451C147.26 16.2368 149.163 17.809 149.163 22.4659C149.163 26.0246 148.078 28.3359 145.35 28.8688C145.852 29.7595 149.31 34.8924 149.807 35.6638C150.51 36.761 150.421 36.9978 148.927 36.9978C147.313 36.9978 146.116 36.9392 145.355 35.6619ZM138.695 25.7577H142.656C144.394 25.7577 144.828 24.4574 144.828 22.4659C144.828 21.0193 144.32 19.7331 142.156 19.7314H138.695V25.7577Z"
                fill="#D31F35"/>
            <path
                d="M130.273 16.2371C131.945 16.2371 131.973 16.2688 131.973 17.513C131.973 18.7583 131.943 19.9708 130.273 19.9708L123.263 19.97V24.5721L129.568 24.5736C131.242 24.5736 131.267 25.1934 131.267 26.322C131.267 27.4489 131.24 28.0699 129.568 28.0699L123.263 28.0671L123.262 33.2638H130.653C132.352 33.2638 132.381 34.4795 132.381 35.7236C132.381 36.9693 132.352 36.999 130.652 36.999L120.567 36.996C119.073 36.996 119.042 36.9668 119.042 35.6618L119.045 17.5709C119.048 16.2655 119.075 16.2368 120.568 16.2368L130.273 16.2371Z"
                fill="#D31F35"/>
            <path
                d="M112.025 35.6661C111.44 34.6567 107.016 27.5172 107.016 27.5172H109.267C109.267 27.5172 104.961 34.511 104.259 35.6661C103.438 36.9415 102.239 37 100.514 37C98.9587 37 98.8712 36.7925 99.6035 35.6661C100.277 34.6274 105.667 26.0353 105.667 26.0353C105.667 26.0353 100.716 18.6554 100.013 17.619C99.2518 16.4324 99.3689 16.2554 100.805 16.2554C102.592 16.2554 103.819 16.3444 104.668 17.619C105.429 18.7459 109.267 24.3746 109.267 24.3746H107.016C107.016 24.3746 110.971 18.5966 111.645 17.619C112.496 16.3444 113.723 16.2554 115.478 16.2554C116.943 16.2554 117.061 16.4619 116.298 17.619C115.538 18.7459 110.646 26.0353 110.646 26.0353C110.646 26.0353 116.007 34.5689 116.68 35.6661C117.383 36.8207 117.324 37 115.774 37C114.044 37 112.818 36.9415 112.025 35.6661Z"
                fill="#D31F35"/>
            <path
                d="M30.0492 10.8916C29.7594 10.0358 29.5496 9.41676 29.2832 8.9734C28.9156 8.36546 28.4191 8.0697 27.7662 8.0697C27.0984 8.0697 26.3081 8.3714 24.8779 8.92439L17.8476 12.3153L18.6755 4.39681C18.7226 2.25456 18.7443 1.27636 18.1058 0.616849C17.5107 0.00012207 16.6209 0.00012207 15.2742 0.00012207C13.8845 0.00012207 12.9666 0.00012207 12.3705 0.63158C11.7375 1.29959 11.7794 2.27609 11.874 4.40984L12.768 12.3148L5.6644 8.92156C4.36072 8.43883 3.49774 8.11985 2.8235 8.11985C2.17417 8.11985 1.68109 8.40201 1.31568 8.98134C1.04997 9.40514 0.857879 9.95756 0.572558 10.8256C0.113074 12.2605 -0.138929 13.0534 0.250275 13.7972C0.670278 14.6035 1.60074 14.8829 3.63942 15.4922L11.433 16.8331L5.97297 22.8482C4.6802 24.4842 4.08939 25.2338 4.20811 26.124C4.32067 26.9588 4.99072 27.45 6.20873 28.3404C7.24445 29.0999 7.92849 29.6025 8.66799 29.6025C9.73929 29.5999 10.3589 28.6381 11.4801 26.8922L15.2797 19.8873L19.1538 26.9016C20.3164 28.6426 20.9585 29.6034 22.0163 29.6034C22.7572 29.6034 23.4215 29.0987 24.424 28.339C25.6381 27.4483 26.307 26.9566 26.419 26.1247C26.5378 25.2335 25.9467 24.4857 24.6517 22.8482L19.1273 16.8342L26.9832 15.4947C29.0328 14.8806 29.9698 14.6021 30.3842 13.7975C30.7635 13.0593 30.4955 12.2468 30.0492 10.8916Z"
                fill="#D31F35"/>
        </svg>
        )