import React from "react";

export const taboolaLogo = (width, height) => (
        <svg xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox="0 0 140 45" fill="none">
            <path d="M14.2941 14.7089H7V8.06274H29.572V14.7089H22.2779V34.1877H14.2941V14.7089Z" fill="#104E93"/>
            <path
                d="M36.2182 25.6606C35.2986 26.0577 34.3581 26.3503 33.3758 26.5175C31.7665 26.8728 30.9305 27.2699 30.9096 28.5448C31.0768 29.6316 32.08 30.384 33.1668 30.2795C34.7552 30.384 36.1137 29.1718 36.2182 27.6043C36.2182 27.5416 36.2182 27.4789 36.2182 27.4162V25.6606ZM43.1361 29.9869C43.0107 31.4499 43.3033 32.9338 43.993 34.2296L36.6153 34.1251C36.4272 33.5608 36.3436 32.9756 36.3645 32.3904H36.2809C34.7343 34.1251 32.5189 34.6267 30.1781 34.6058C26.5206 34.6058 23.511 32.8084 23.5319 28.8374C23.5319 22.86 30.5752 23.1108 34.2536 22.442C35.2568 22.233 36.1973 21.9822 36.1973 20.7491C36.1973 19.516 35.006 19.0144 33.8356 19.0144C31.6202 19.0144 31.1604 20.1221 31.1186 20.9372H24.4097C24.6605 15.6286 29.6974 14.7717 34.1909 14.7926C43.2615 14.7717 43.1779 18.5128 43.1152 22.1703V29.9869H43.1361Z"
                fill="#104E93"/>
            <path
                d="M55.07 20.122C52.4784 20.122 51.8932 22.5464 51.8723 24.7618C51.8514 26.9772 52.4157 29.4852 55.0282 29.4852C57.6198 29.4852 58.2259 27.0608 58.2259 24.8036C58.2259 22.5464 57.6616 20.122 55.07 20.122ZM45.0171 8H52.2485L52.2067 17.3841H52.2694C53.4816 15.733 55.4253 14.7925 57.4526 14.8552C63.7853 14.8552 65.4782 20.2892 65.4573 24.7409C65.4573 29.4852 62.803 34.752 57.4526 34.7311C53.9205 34.7311 52.8337 33.3517 51.935 32.1395H51.8723V34.1668H45.0589L45.0171 8Z"
                fill="#104E93"/>
            <path
                d="M75.5729 13.3087C72.835 13.3087 72.4588 15.963 72.4379 17.9485C72.417 19.9549 72.7723 22.651 75.5102 22.6719C78.2481 22.6928 78.6243 19.9967 78.6452 17.9903C78.6661 15.9839 78.3108 13.3714 75.5729 13.3087ZM75.4893 27.9387C68.3833 27.9387 65.2065 23.1735 65.2274 17.9276C65.2483 12.6817 68.4878 8.02098 75.6147 8.06278C82.7416 8.10458 85.8975 12.7862 85.8766 18.053C85.8348 23.2989 82.5953 27.9805 75.4893 27.9387Z"
                fill="#104E93"/>
            <path
                d="M93.4842 13.3087C90.7463 13.3087 90.3701 15.963 90.3701 17.9694C90.3701 19.9758 90.7045 22.6719 93.4424 22.6928C96.1803 22.7137 96.5565 20.0176 96.5565 18.0112C96.5565 16.0048 96.2221 13.3296 93.4842 13.3087ZM93.4006 27.9387C86.2946 27.9387 83.1178 23.1735 83.1387 17.9276C83.1805 12.6817 86.3991 8.02098 93.526 8.06278C100.653 8.10458 103.809 12.7862 103.788 18.053C103.746 23.2989 100.507 27.9805 93.4006 27.9387Z"
                fill="#104E93"/>
            <path d="M104.54 8.06274H111.604V34.1877H104.54V8.06274V8.06274Z" fill="#104E93"/>
            <path
                d="M124.772 25.5978C123.852 25.9949 122.911 26.2875 121.929 26.4547C120.32 26.81 119.484 27.2071 119.463 28.482C119.63 29.5688 120.633 30.3212 121.72 30.2167C123.288 30.3212 124.667 29.1299 124.751 27.5415C124.751 27.4579 124.751 27.3534 124.751 27.2698L124.772 25.5978ZM131.689 29.9241C131.689 31.3453 131.606 33.0173 132.4 34.2504H125.148C124.96 33.6443 124.876 32.9964 124.897 32.3485H124.813C123.267 34.0832 121.051 34.5848 118.711 34.5639C115.053 34.5639 112.043 32.7665 112.064 28.7955C112.064 22.8181 119.087 23.0689 122.786 22.4001C123.768 22.212 124.751 21.9403 124.772 20.7281C124.772 19.5159 123.58 18.9934 122.41 18.9934C120.194 18.9934 119.735 20.1011 119.693 20.9162H112.984C113.235 15.5867 118.272 14.7507 122.765 14.7716C131.815 14.8134 131.71 18.5754 131.689 22.2329V29.9241Z"
                fill="#104E93"/>
            <path
                d="M67.9235 27.9385C73.044 30.1957 78.5825 31.3243 84.1628 31.2825C90.2238 31.2825 94.8636 30.1748 100.716 27.9385V34.2503C95.5742 36.8419 89.9103 38.2004 84.1628 38.2213C77.9973 38.2213 73.5665 36.9255 67.9235 34.2503V27.9385Z"
                fill="#104E93"/>
        </svg>
)